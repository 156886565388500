<template>
  <el-dialog :visible="visible" @update:visible="updateVisible" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
    <el-tabs type="border-card" @tab-click="handleClick" :value="value">
      <el-tab-pane :label="item.name" :key="'m_1'+item.id" v-for="(item , i) in config">
        <el-tabs tab-position="left" style="height: 300rem;">
          <el-tab-pane :label="subMenu.name" :key="'m_2'+subMenu.id" v-for="(subMenu,i2) in item.child" v-if="leave>=2">
            <slot :data="subMenu" :index="[i,i2]"></slot>
          </el-tab-pane>
        </el-tabs>
        <slot :data="item" v-if="leave <= 1"></slot>
      </el-tab-pane>

    </el-tabs>
  <div slot="footer">
    <el-button @click="$emit('update:visible',false);$emit('cancel')">取消</el-button>
    <el-button type="primary" @click="$emit('confirm')">确定</el-button>
  </div>
  </el-dialog>
</template>

<script>
export default {
  name: "permission",
  props:{
    // 级别最低1级最高两级  两级以上在slot中实现
    // 如三级菜单 ， 填2
    leave:{
      type:Number,
      default:()=>2
    },
    value: {
      type:[Number,String],
      default:()=>0
    },
    config:{
      type:Array,
      default:()=>[]
    },
    // 子元素的key
    childKey:{
      type:String,
      default:()=>"child"
    },
    visible:Boolean
  },
  methods:{
    handleClick(e){
      this.$emit("input",e.index);
    },
    updateVisible(e){
      this.$emit("update:visible",e);
    }
  }
}
</script>

<style scoped>

</style>
